.App {
  text-align: center;
  background-image: url('images/background3.jpg');
}

@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/greatvibes/v19/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

a {
  text-decoration: none;
}

a:hover {
  font-style: italic;
}

ol, ul {
  line-height: 2;
}

.nav-home {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.nav-home:hover {
  color: rgb(110, 110, 110);
  font-style: normal;
}

.textTitle {
  color: black;
  display: inline;
}

.textTitle:hover {
  color: rgb(80, 80, 80);
}

.bookcover:hover {
  transform: scale(1.025);
}

.centered-hr {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.rightcol-hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}

html {
  scroll-behavior: auto !important;
}

@media (min-width: 1200px) and (max-width: 1900px) {
  .custom-col {
    width: 50% !important; 
  }

  .custom-col > .row {
    height: calc(100% / 3); 
  }
}

.SearchButton {
  border: 1px solid black;
  max-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 3px;
  border-radius: 10px;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  color: black;
  display: block;
  white-space: normal;
  word-wrap: break-word;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.LeftColumn {
  padding: 60px 40px 0px 40px;
  border-right: 1px solid lightgrey;
  border-top: none;
}

.LeftColumn.mobile {
  padding: 10px 40px 0px 40px;
  border-right: none;
}

.RightColumn {
  background: #ffffff  url('images/newbg.jpg');
  text-align: left;
  padding: 20px 20px 0px 20px;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

.RightColumn.mobile {
  border-right: none;
  padding: 20px 20px 0px 20px;
  min-height: 100vh;
}

.PageTitle {
  text-align: center;
  margin-bottom: 20px;
}

.circle {
  display: inline-block;
  width: 50px; 
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid lightgrey;
  text-align: center;
  line-height: 45px; 
  margin-bottom: 15px;
  font-weight: bold;
}

.Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.main-content {
  flex: 1; 
}

.pdf-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.responsive-embed {
  position: relative;
  top: 0;
  left: 0;
  width: 800px;
  height: 1200px;
  max-width: 100%;
  border: none;
}

.footer {
  background-color: #5f5f5f;
  color: white;
  text-align: center;
  padding: 10px;
}





